import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);
//import colors from 'vuetify/lib/util/colors'

const vuetify = new Vuetify({

    theme: {
        themes: {
            dark: {
                primary: '#82B1FF',
                navbar2:'#1E1E1E',
                secondary: '#ffffff',
                toolbarbg:'#313131',
                cansel:'#929292',
                nocolor:'#1E1E1E',
                error: '#FF5252',
                info: '#2196F3',
                success: '#4CAF50',
                warning: '#FFC107',
                background:'#000000',
                anchor:'#000000',
                iconscolor:'#dcdcdc'

            },
            light: {
                primary: '#82B1FF',
                navbar2:'#7680bf',
                secondary: '#ffffff',
                accent: '#82B1FF',
                error: '#FF5252',
                info: '#2196F3',
                success: '#4CAF50',
                warning: '#FFC107',
                toolbarbg:'#fff',
                cansel:'#929292',
                nocolor:'#ffffff',
                text:'#000000',
                base:'#FFC107',
                iconscolor:'#848484'
            },



        },

       // light:true,
        dark:true
    },
})

export default vuetify