<template>
    <div>
        <PrintBill/>
<v-sheet outlined class="m-1">
    <v-row>
        <v-col>
            <v-menu
                    offset-y
                    :close-on-content-click="false"
            >
                <template v-slot:activator="{ on, attrs }">
                    <v-btn
                            small
                            fab
                            color="warning"
                            v-bind="attrs"
                            v-on="on"

                    >
                        <v-icon>mdi-filter</v-icon>
                    </v-btn>
                </template>
                <v-list>

                    <v-list-item  >
                        <template >
                            <v-list-item-action>
                                <v-checkbox  v-model="display.col_bills_sand_id"></v-checkbox>
                            </v-list-item-action>
                            <v-list-item-content>
                                <v-list-item-title>رقم الوصل</v-list-item-title>
                            </v-list-item-content>
                        </template>
                    </v-list-item>
                    <v-list-item  >
                        <template >
                            <v-list-item-action>
                                <v-checkbox  v-model="display.col_bills_sand_moneyType"></v-checkbox>
                            </v-list-item-action>
                            <v-list-item-content>
                                <v-list-item-title>نوع الوصل </v-list-item-title>
                            </v-list-item-content>
                        </template>
                    </v-list-item>
                    <v-list-item  >
                        <template >
                            <v-list-item-action>
                                <v-checkbox  v-model="display.col_bills_cost_name"></v-checkbox>
                            </v-list-item-action>
                            <v-list-item-content>
                                <v-list-item-title>اسم الزبون </v-list-item-title>
                            </v-list-item-content>
                        </template>
                    </v-list-item>

                    <v-list-item  >
                        <template >
                            <v-list-item-action>
                                <v-checkbox  v-model="display.col_bills_cost_user"></v-checkbox>
                            </v-list-item-action>
                            <v-list-item-content>
                                <v-list-item-title>يوزر الزبون</v-list-item-title>
                            </v-list-item-content>
                        </template>
                    </v-list-item>

                    <v-list-item>
                        <template >
                            <v-list-item-action>
                                <v-checkbox  v-model="display.col_bills_sand_money"></v-checkbox>
                            </v-list-item-action>
                            <v-list-item-content>
                                <v-list-item-title>مبلغ الوصل</v-list-item-title>
                            </v-list-item-content>
                        </template>
                    </v-list-item>
                    <v-list-item>
                        <template>
                            <v-list-item-action>
                                <v-checkbox  v-model="display.col_bills_sand_moneyin"></v-checkbox>
                            </v-list-item-action>
                            <v-list-item-content>
                                <v-list-item-title>الواصل</v-list-item-title>
                            </v-list-item-content>
                        </template>
                    </v-list-item>
                    <v-list-item>
                        <template>
                            <v-list-item-action>
                                <v-checkbox  v-model="display.col_bills_sand_cardType"></v-checkbox>
                            </v-list-item-action>
                            <v-list-item-content>
                                <v-list-item-title> نوع الاشتراك</v-list-item-title>
                            </v-list-item-content>
                        </template>
                    </v-list-item>
                    <v-list-item  >
                        <template >
                            <v-list-item-action>
                                <v-checkbox  v-model="display.col_bills_sand_date"></v-checkbox>
                            </v-list-item-action>
                            <v-list-item-content>
                                <v-list-item-title> تاريخ الوصل</v-list-item-title>
                            </v-list-item-content>
                        </template>
                    </v-list-item>
                    <v-list-item  >
                        <template >
                            <v-list-item-action>
                                <v-checkbox  v-model="display.col_bills_sand_user"></v-checkbox>
                            </v-list-item-action>
                            <v-list-item-content>
                                <v-list-item-title>الموظف</v-list-item-title>
                            </v-list-item-content>
                        </template>
                    </v-list-item>






                    <v-btn color="warning" @click="save_bills_columns">
                        <v-icon>mdi-content-save</v-icon>
                        <span>حفظ</span>
                    </v-btn>

                </v-list>
            </v-menu>
        </v-col>
        <v-col cols="12" md="3">
            <v-menu
                    ref="menu"
                    v-model="search.menu"
                    :close-on-content-click="false"
                    :return-value.sync="search.dates"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
            >
                <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                            v-model="search.dates"
                            multiple
                            chips
                            small-chips
                            prepend-icon="mdi-calendar"
                            readonly
                            clearable
                            v-bind="attrs"
                            v-on="on"
                            @click:clear="clear_dates"

                    ></v-text-field>
                </template>
                <v-date-picker
                        v-model="search.dates"
                        multiple
                        no-title
                        scrollable

                >
                    <v-spacer></v-spacer>
                    <v-btn
                            text
                            color="primary"
                            @click="search.menu = false"
                    >
                        اللغاء
                    </v-btn>
                    <v-btn
                            text
                            color="primary"
                            @click="$refs.menu.save(search.dates);search_bill()"

                    >
                        فلترة
                    </v-btn>
                </v-date-picker>
            </v-menu>
        </v-col>
        <v-col cols="12" md="3" style="display: none">
            <v-select v-if="$store.state.login.level==1" label="الموظف"  :items="$store.state.users.users" item-text="Fullname" item-value="user_name" v-model="search.sand_user" clearable @change="search_bill" @click:clear="clear_user"></v-select>
        </v-col>
        <v-col cols="12" md="3">
            <v-row>
                <v-col cols="8">
                    <div class="text-center">
                        <v-select :items="pages" item-value="value" item-text="label" v-model="page_size"  @change="change_page_size">

                        </v-select>
                    </div>
                </v-col>

                <v-col cols="4" class="h-100  pa-6">
                    <span>
                        <v-btn text>
                            <strong>:النتائج</strong>
                        </v-btn>

                    </span>
                </v-col>
            </v-row>


        </v-col>

    </v-row>
</v-sheet>





        <v-simple-table
                class="text-center mb-16" style="height: 80% !important;">
            <template v-slot:default>
                <thead>
                <tr>
                    <td>خيارات</td>
                    <td v-if="display.col_bills_sand_user">الموظف</td>
                    <td v-if="display.col_bills_sand_date">تاريخ الوصل</td>
                    <td v-if="display.col_bills_sand_cardType">نوع الاشتراك</td>
                    <td v-if="display.col_bills_sand_moneyin">الواصل</td>
                    <td v-if="display.col_bills_sand_money">مبلغ الوصل</td>
                    <td v-if="display.col_bills_cost_user">يوزر الزبون</td>
                    <td v-if="display.col_bills_cost_name">اسم الزبون</td>
                    <td v-if="display.col_bills_sand_moneyType">نوع الوصل</td>
                    <td v-if="display.col_bills_sand_id">رقم الوصل</td>

                    <td>ت</td>

                </tr>
                <tr>

                    <th class="text-center"></th>
                    <th v-if="display.col_bills_sand_user"  class="text-center"><v-text-field v-model="search.sand_user" @keyup="search_bill" flat prepend-icon="mdi-magnify"></v-text-field></th>
                    <th v-if="display.col_bills_sand_date" class="text-center"><v-text-field v-model="search.Sand_date" @keyup="search_bill" flat prepend-icon="mdi-magnify"></v-text-field></th>
                    <th v-if="display.col_bills_sand_cardType" class="text-center"><v-text-field v-model="search.Sand_cardtype" @keyup="search_bill" flat prepend-icon="mdi-magnify"></v-text-field></th>
                    <th v-if="display.col_bills_sand_moneyin" class="text-center"><v-text-field v-model="search.Sand_moneyin" @keyup="search_bill" flat prepend-icon="mdi-magnify"></v-text-field></th>
                    <th v-if="display.col_bills_sand_money" class="text-center"><v-text-field v-model="search.Sand_money" @keyup="search_bill" flat prepend-icon="mdi-magnify"></v-text-field></th>
                    <th v-if="display.col_bills_cost_user" class="text-center"><v-text-field v-model="search.cost_user" @keyup="search_bill"  flat prepend-icon="mdi-magnify"></v-text-field></th>
                    <th v-if="display.col_bills_cost_name" class="text-center"><v-text-field v-model="search.cost_name" @keyup="search_bill"  flat prepend-icon="mdi-magnify"></v-text-field></th>
                    <th v-if="display.col_bills_sand_moneyType" class="text-center"><v-text-field v-model="search.Sand_moneyType" @keyup="search_bill" flat prepend-icon="mdi-magnify"></v-text-field></th>
                    <th v-if="display.col_bills_sand_id" class="text-center"><v-text-field v-model="search.Sand_id" @keyup="search_bill" flat prepend-icon="mdi-magnify"></v-text-field></th>

                    <th  class="text-center"></th>


                </tr>

                </thead>
                <tbody>
                <tr v-for="(item,i) in pageOfItems" :key="i">
                    <td>

                        <v-row
                                align="center"
                                justify="space-around"
                        >
                                <v-btn  v-if="$store.state.login.level==1" fab x-small color="error" @click="open_delete(item.Sand_id)" >
                                    <v-icon  class="secondary--text" >mdi-delete</v-icon>
                                </v-btn>

                                <v-btn fab x-small color="blue" @click="print_sand(item)">
                                    <v-icon class="secondary--text">mdi-printer</v-icon>
                                </v-btn>
                        </v-row>




                    </td>
                    <td v-if="display.col_bills_sand_user">{{item.sand_user}}</td>
                    <td v-if="display.col_bills_sand_date">{{item.Sand_date |date}}</td>
                    <td v-if="display.col_bills_sand_cardType">{{item.Sand_cardtype}}</td>
                    <td v-if="display.col_bills_sand_moneyin">{{Math.trunc(item.Sand_moneyin)}}</td>
                    <td v-if="display.col_bills_sand_money">{{Math.trunc(item.Sand_money)}}</td>
                    <td v-if="display.col_bills_cost_user">{{item.cost_user}}</td>
                    <td v-if="display.col_bills_cost_name">{{item.cost_name}}</td>
                    <td v-if="display.col_bills_sand_moneyType">{{item.Sand_moneyType}}</td>
                    <td v-if="display.col_bills_sand_id">{{item.Sand_id}}</td>
                    <td>{{bills.indexOf(item)+1}}</td>




                </tr>
                </tbody>
            </template>
        </v-simple-table>
        <v-dialog
                v-model="dialog_delete"
                persistent
                max-width="290"
        >

            <v-card :loading="false">
                <v-card-title class="headline text-right error" >
                    <span>
                        <v-icon class="secondary--text">mdi-delete</v-icon>
                    <small class="ma-4 secondary--text ">حذف وصل</small>
                    </span>

                </v-card-title>
                <v-card-text class="text-center p-4 font-weight-bold">
                    هل انت متاكد من الحذف ؟
                </v-card-text>
                <v-divider></v-divider>
                <v-card-actions>
                    <v-spacer></v-spacer>

                    <v-btn

                            rounded
                            class="cansel secondary--text"
                            @click="dialog_delete = false"
                    >

                        اللغاء
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                    <v-btn
                            color="red"
                            rounded
                            class="secondary--text"
                            @click="delete_bill"
                    >
                        حذف
                        <v-icon class="secondary--text">mdi-delete</v-icon>

                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-footer app>
            <v-simple-table
                    class="text-center w-100">
                <template v-slot:default>
                    <thead>
                    <tr>
                        <th>{{sum_moneyin | money}}</th>
                        <th>مجموع الواصل</th>

                        <th>{{sum_money | money}}</th>
                        <th>مجموع مبلغ الوصل</th>

                    </tr>
                    </thead>
                </template>
            </v-simple-table>
            <div class="card text-center w-100 nocolor" >
                <div class="card-footer pb-0 pt-3">
                    <jw-pagination :items="bills" :pageSize="page_size"  @changePage="onChangePage" ></jw-pagination>
                </div>
            </div>
        </v-footer>



    </div>

</template>

<script>

    import JwPagination from 'jw-vue-pagination';
    import PrintBill from "@/components/Customers/PrintBill";
    import axios from 'axios';
    import moment from "moment";
    export default {
        name: "Bills",
        filters: {
            money:function(value)
            {
                return Math.trunc(value);
            },
            sandtype: function (value) {
                if (value == 1 || value =="1") {
                    return "تفعيل"
                }
                if (value == 2 || value =="2") {
                    return "تسديد"
                }
                if (value == 6 || value =="6") {
                    return "دين"
                }


            },
            date:function (value) {
            if(value != null  && value != "")
            {
                return moment(value).format('yyyy-MM-DD hh:mm a')
            }
    }
        },
        components: {JwPagination,PrintBill},
        data() {
            return {
                page_size:100,
                pages:[
                    {label:'100',value:100},
                    {label:'200',value:200},
                    {label:'500',value:500},
                    {label:'1000',value:1000},
                    {label:'الكل ('+this.$store.state.bills.bills.length+')',value:this.$store.state.bills.bills.length},



                ],
                pageOfItems: [],
                bills: this.$store.state.bills.bills,
                search: {
                    dates:[],
                    menu:false,
                    Sand_date: '',
                    Sand_cardtype: '',
                    Sand_moneyType: '',
                    Sand_moneyin: '',
                    Sand_money: '',
                    cost_name: '',
                    cost_user:'',
                    sand_user:'',
                    Sand_id:''
                },
                dialog_delete:false,
                sand_id_to_delete:'',
                sum_money:0,
                sum_moneyin:0,
                display:{
                    col_bills_sand_id:true,
                    col_bills_sand_moneyType:true,
                    col_bills_cost_name:true,
                    col_bills_cost_user:true,
                    col_bills_sand_money:true,
                    col_bills_sand_moneyin:true,
                    col_bills_sand_date:true,
                    col_bills_sand_cardType:true,
                    col_bills_sand_user:true


                },

            }
        },
        methods: {
            onChangePage(pageOfItems) {
                // update page of items
                this.pageOfItems = pageOfItems;
            },
            search_bill() {
                var filterd = this.$store.state.bills.bills;
                filterd.map(x => {
                    if (x.cost_name == null || x.cost_name == "") {
                        x.cost_name = "";
                    }
                    x.Sand_id = x.Sand_id.toString();

                    if (x.Sand_date == null || x.Sand_date == "") {
                        x.Sand_date = "";
                    }

                    if (x.Sand_moneyType == "1") {
                        x.Sand_moneyType = "تفعيل"
                    }
                    if (x.Sand_moneyType == "2") {
                        x.Sand_moneyType = "تسديد"
                    }
                    if (x.Sand_moneyType == "6") {
                        x.Sand_moneyType = "دين"
                    }


                    if (x.Sand_moneyin == null || x.Sand_moneyin == "") {
                        x.Sand_moneyin = "";
                    }
                    if (x.Sand_money == null || x.Sand_money == "") {
                        x.Sand_money = "";
                    }

                   // x.Sand_date = x.Sand_date.toString();
                    if(x.Sand_moneyType != null)
                    {
                        x.Sand_moneyType = x.Sand_moneyType.toString();
                    }else{
                        x.Sand_moneyType ="";
                    }
                    if(x.Sand_moneyin != null)
                    {
                        x.Sand_moneyin = x.Sand_moneyin.toString();
                    }else{
                        x.Sand_moneyin ="";
                    }
                    if(x.Sand_money != null)
                    {
                        x.Sand_money = x.Sand_money.toString();
                    }else{
                        x.Sand_money ="";
                    }
                    if(x.sand_user != null)
                    {
                        x.sand_user = x.sand_user.toString();

                    }else{
                        x.sand_user = "";
                    }

                    return x;
                });
                filterd = filterd.filter(item => item.cost_name.match(this.search.cost_name));
                filterd = filterd.filter(item => item.Sand_date.match(this.search.Sand_date));
                filterd = filterd.filter(item => item.Sand_moneyType.match(this.search.Sand_moneyType));
                filterd = filterd.filter(item => item.Sand_moneyin.match(this.search.Sand_moneyin));
                filterd = filterd.filter(item => item.Sand_money.match(this.search.Sand_money));
                filterd = filterd.filter(item => item.Sand_cardtype.match(this.search.Sand_cardtype));
                filterd = filterd.filter(item => item.cost_user.match(this.search.cost_user));
                filterd = filterd.filter(item => item.sand_user.match(this.search.sand_user));
                filterd = filterd.filter(item => item.Sand_id.match(this.search.Sand_id));


                if(this.search.sand_user != null)
                {
                    filterd = filterd.filter(item => item.sand_user.match(this.search.sand_user));
                }

                if(this.search.dates != null )
                {
                    if (this.search.dates[0] != "" && this.search.dates[0] != null) {
                        filterd = filterd.filter(item => item.Sand_date >= this.search.dates[0]);
                    }
                    if (this.search.dates[1] != "" && this.search.dates[1] != null) {
                        this.search.dates[1] = moment().add(1,'day').format('YYYY-MM-DD');

                        filterd = filterd.filter(item => item.Sand_date <= this.search.dates[1]);
                    }
                }
                console.log(this.search.dates)



                this.pageOfItems = filterd;
                this.bills=filterd;
                this.get_sum_money(filterd);
            },
            clear_dates(){

                this.search.dates = [];
                this.search.dates[0] ="";
                this.search.dates[1] ="";


                this.search_bill();

            },
            clear_user(){

                this.search.sand_user = '';
                this.search_bill();

            },
            open_delete(id) {
                this.dialog_delete = true;
                this.sand_id_to_delete = id;
            },
            delete_bill() {
                const id= this.sand_id_to_delete ;
                axios.post("customers/delete-sand-customer",{'Sand_id':id})
                    .then(res=>{
                        this.$store.commit('GET_BILLS');
                        this.dialog_delete = false;
                    })
            },
            print_sand(item){
                //console.log(this.$store.state.bills.forms.print);
                this.$store.state.bills.forms.print =false;
                this.$store.state.customers.target_customer_id = item.cost_id;
                this.$store.state.bills.target_sand_id = item.Sand_id;
                this.$store.state.bills.forms.print =true;

            },
            get_sum_money(bills) {
                var sum_money2 = 0;
                var sum_moneyin2 = 0;

                bills.map(x=>{
                    if(parseFloat(x.Sand_money) > 0 )
                    {
                        sum_money2 += parseFloat(x.Sand_money);
                    }
                    if(parseFloat(x.Sand_moneyin) > 0 )
                    {
                        sum_moneyin2 += parseFloat(x.Sand_moneyin);
                    }

                    //console.log(sum_money2)

                });
                if(sum_money2 != null)
                {
                    this.sum_money = sum_money2;
               }
               if(sum_moneyin2 !=null)
               {
                   this.sum_moneyin = sum_moneyin2;
               }


            },
            set_date_today_default() {
                this.search.dates[0] =moment().format('YYYY-MM-DD');
                this.search.dates[1] =moment().add(1,'day').format('YYYY-MM-DD');
                this.search_bill();
            },
            change_page_size(){
                this.search_bill();
            },
            save_bills_columns(){
                axios.post('bills/custom-bills-columns',{'columns':this.display,'user_id':this.$store.state.login.user_id})
                    .then(res=>{
                        this.$fire({title: "نجح", text: "تم حفظ الشكل بنجاح", type: "success", timer: 1000});
                        localStorage.setItem("columns_loaded","false");
                        this.$store.commit("get_bills_columns",true);
                    })
            },
            async get_bills_columns(){
                await this.$store.commit("get_bills_columns",false);
                var data = JSON.parse(localStorage.getItem('bills_columns'));
                //console.log('data is ' +data)
                if(data !=  undefined)
                {
                    data.map(x=>{
                        this.display.col_bills_sand_id= Boolean(parseInt(x.col_bills_sand_id));
                        this.display.col_bills_sand_moneyType=Boolean(parseInt(x.col_bills_sand_moneyType));
                        this.display.col_bills_cost_name=Boolean(parseInt(x.col_bills_cost_name));
                        this.display.col_bills_cost_user=Boolean(parseInt(x.col_bills_cost_user));
                        this.display.col_bills_sand_money=Boolean(parseInt(x.col_bills_sand_money));
                        this.display.col_bills_sand_moneyin=Boolean(parseInt(x.col_bills_sand_moneyin));
                        this.display.col_bills_sand_date=Boolean(parseInt(x.col_bills_sand_date));
                        this.display.col_bills_sand_cardType=Boolean(parseInt(x.col_bills_sand_cardType));
                        this.display.col_bills_sand_user=Boolean(parseInt(x.col_bills_sand_user));
                        this.display.col_cost_admin=false;


                    });
                }



            },

        },
        watch: {
            watch_bills: function (new_bills) {
                this.pageOfItems = new_bills
                this.bills = new_bills;
                this.get_sum_money(new_bills);
                this.set_date_today_default();
                this.search_bill();
            }
        },
        computed: {
            watch_bills: function () {
                return this.$store.state.bills.bills
            }
        },
        created() {
            this.$store.commit("GET_BILLS",true);
            this.$store.state.bills.bills.map(x => {

                if (x.Sand_moneyType == "1") {
                    x.Sand_moneyType = "تفعيل"
                }
                if (x.Sand_moneyType == "2") {
                    x.Sand_moneyType = "تسديد"
                }
                if (x.Sand_moneyType == "6") {
                    x.Sand_moneyType = "دين"
                }
                x.Sand_date = x.Sand_date.substr(0,10);
                return x;

            });
            this.get_bills_columns();
            this.get_sum_money(this.$store.state.bills.bills);

        },




    }
</script>

<style scoped>

</style>