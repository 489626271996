<template>
<div>
    <v-card outlined>
        <v-card-title>

            <v-row justify="center">
                <v-col cols="12" lg="2">
                    <v-select style="text-align: center !important;" @change="changeMoneyType" v-model="money_type" :items="money_types" item-value="value" item-text="value" outlined label="العملة"></v-select>
                </v-col>
                <v-col cols="12" lg="2">
                    <v-menu
                            ref="menu2"
                            v-model="search.menu2"
                            :close-on-content-click="false"
                            :return-value.sync="search.date2"
                            transition="scale-transition"
                            offset-y
                            min-width="auto"
                    >
                        <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                                    v-model="search.date2"
                                    chips
                                    small-chips
                                    prepend-icon="mdi-calendar"
                                    readonly
                                    clearable
                                    v-bind="attrs"
                                    v-on="on"
                                    @click:clear="clear_date2"
                                    label="الى تاريخ"

                            ></v-text-field>
                        </template>
                        <v-date-picker
                                v-model="search.date2"
                                no-title
                                scrollable

                        >
                            <v-spacer></v-spacer>
                            <v-btn
                                    text
                                    color="primary"
                                    @click="search.menu2 = false"
                            >
                                اللغاء
                            </v-btn>
                            <v-btn
                                    text
                                    color="primary"
                                    @click="$refs.menu2.save(search.date2);search_credits()"

                            >
                                فلترة
                            </v-btn>
                        </v-date-picker>
                    </v-menu>
                </v-col>
                <v-col cols="12" lg="2">
                    <v-menu
                            ref="menu1"
                            v-model="search.menu1"
                            :close-on-content-click="false"
                            :return-value.sync="search.date1"
                            transition="scale-transition"
                            offset-y
                            min-width="auto"
                    >
                        <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                                    v-model="search.date1"
                                    chips
                                    small-chips
                                    prepend-icon="mdi-calendar"
                                    readonly
                                    clearable
                                    v-bind="attrs"
                                    v-on="on"
                                    @click:clear="clear_date1"
                                    label="من تاريخ"
                            ></v-text-field>
                        </template>
                        <v-date-picker
                                v-model="search.date1"
                                no-title
                                scrollable

                        >
                            <v-spacer></v-spacer>
                            <v-btn
                                    text
                                    color="primary"
                                    @click="search.menu1 = false"
                            >
                                اللغاء
                            </v-btn>
                            <v-btn
                                    text
                                    color="primary"
                                    @click="$refs.menu1.save(search.date1);search_credits()"

                            >
                                فلترة
                            </v-btn>
                        </v-date-picker>
                    </v-menu>
                </v-col>



                <v-spacer></v-spacer>
                <span class="text-h5 mr-2">
                ارصدة الوكلاء
                    <v-icon large>mdi-currency-usd</v-icon>
                </span>

            </v-row>

        </v-card-title>
        <v-divider></v-divider>
        <v-card-text>
            <v-simple-table
                    class="text-center vh-90 mb-16">
                <template v-slot:default>
                    <thead>
                    <tr>

                        <td>مدخل البيانات</td>
                        <td>طريقة الاستلام</td>
                        <td>الملاحظات</td>
                        <td>الوصف</td>
                        <td>التاريخ</td>
                        <td>الربح</td>
                        <td>سعر التكلفة</td>
                        <td>المبلغ الواصل</td>
                        <td>المبلغ</td>
                        <td>العملة</td>
                        <td>اسم الوكيل</td>
                        <td>يوزر الوكيل</td>
                        <td>نوع الوصل</td>
                        <td>رقم الوصل </td>

                    </tr>
                    <tr>

                        <th  class="text-center"><v-text-field v-model="search.sand_user" @keyup="search_credits"  flat prepend-icon="mdi-magnify"></v-text-field></th>
                        <th  class="text-center"><v-text-field  v-model="search.Sand_operation" @keyup="search_credits" flat prepend-icon="mdi-magnify"></v-text-field></th>
                        <th  class="text-center"><v-text-field v-model="search.sand_notes"  @keyup="search_credits" flat prepend-icon="mdi-magnify"></v-text-field></th>
                        <th  class="text-center"><v-text-field v-model="search.sand_desc" @keyup="search_credits"  flat prepend-icon="mdi-magnify"></v-text-field></th>
                        <th  class="text-center"><v-text-field v-model="search.sand_date"  @keyup="search_credits" flat prepend-icon="mdi-magnify"></v-text-field></th>
                        <th  class="text-center"><v-text-field v-model="search.gain" @keyup="search_credits"  flat prepend-icon="mdi-magnify"></v-text-field></th>
                        <th  class="text-center"><v-text-field v-model="search.cost_price" @keyup="search_credits" flat prepend-icon="mdi-magnify"></v-text-field></th>
                        <th  class="text-center"><v-text-field v-model="search.Sand_moneyin"  @keyup="search_credits" flat prepend-icon="mdi-magnify"></v-text-field></th>
                        <th  class="text-center"><v-text-field v-model="search.Sand_money" @keyup="search_credits"  flat prepend-icon="mdi-magnify"></v-text-field></th>
                        <th  class="text-center"><v-text-field v-model="search.currency" @keyup="search_credits"  flat prepend-icon="mdi-magnify"></v-text-field></th>
                        <th  class="text-center"><v-text-field v-model="search.brig_name" @keyup="search_credits" flat prepend-icon="mdi-magnify"></v-text-field></th>
                        <th  class="text-center"><v-text-field v-model="search.brig_userAflet" @keyup="search_credits" flat prepend-icon="mdi-magnify"></v-text-field></th>
                        <th  class="text-center"><v-text-field v-model="search.tree_name" @keyup="search_credits" flat prepend-icon="mdi-magnify"></v-text-field></th>
                        <th  class="text-center"><v-text-field v-model="search.sand_id" @keyup="search_credits" flat prepend-icon="mdi-magnify"></v-text-field></th>



                    </tr>

                    </thead>
                    <tbody>
                    <tr v-for="(item,i) in pageOfItems" :key="i">
                        <td>{{item.sand_user }}</td>
                        <td>{{item.Sand_operation }}</td>
                        <td>{{item.Sand_notes   }}</td>
                        <td>{{item.sand_desc   }}</td>
                        <td><span v-if="item.Sand_date != null">{{item.Sand_date.substring(0,10)}}</span></td>
                        <td>{{item.gain | money_f}}</td>
                        <td>{{item.cost_price | money_f}}</td>
                        <td>{{item.Sand_moneyin | money_f}}</td>
                        <td>{{item.Sand_money | money_f}}</td>
                        <td>{{item.currency}}</td>
                        <td>{{item.brig_name }}</td>
                        <td>{{item.brig_userAflet  }}</td>
                        <td>{{item.tree_name }}</td>
                        <td>{{item.Sand_id  }}</td>

                    </tr>
                    </tbody>
                </template>
            </v-simple-table>
            <v-simple-table
                    class="text-center vh-90 mb-16">
                <template v-slot:default>
                    <tbody>
                        <tr>
                            <td>
                                {{sum_money}}
                            </td>
                            <td>
                                مجموع المبالغ
                            </td>

                            <td>

                                {{sum_moneyin}}
                            </td>

                            <td>
                                مجموع الواصل
                            </td>

                        </tr>
                    </tbody>
                </template>
            </v-simple-table>

            <v-footer app>
                <div class="card text-center w-100 nocolor" >
                    <div class="card-footer pb-0 pt-3">
                        <jw-pagination :items="credits"   @changePage="onChangePage" ></jw-pagination>
                    </div>
                </div>
            </v-footer>
        </v-card-text>
    </v-card>
</div>
</template>

<script>

    import JwPagination from 'jw-vue-pagination';
    import moment from "moment";
    export default {
        name: "Credits",
        data(){
            return{
                credits:this.$store.state.credits.credits,
                pageOfItems:[],
                menu1:false,
                menu2:false,
                sum_money:0,
                sum_moneyin:0,
                search:{
                    sand_id:'',
                    tree_name:'',
                    brig_userAflet:'',
                    brig_name:'',
                    currency:'',
                    Sand_money:'',
                    Sand_moneyin:'',
                    cost_price:'',
                    gain:'',
                    sand_date:'',
                    sand_desc:'',
                    sand_notes:'',
                    sand_user:'',
                    Sand_operation:'',
                    date1:'',
                    date2:'',


                },
                money_types:[
                    {value:'دينار'},
                    {value:'دولار'},
                ],
                money_type:''
            }
        },
        filters:{
            money_f:function (value) {
                if(value != null || value != "")
                {
                    return Math.trunc(value);
                }
                return value;
            }
        },
        components:{
            JwPagination
        },
        methods:{
            onChangePage(pageOfItems) {
                // update page of items
                this.pageOfItems = pageOfItems;
            },
            changeMoneyType()
            {
                if(this.money_type == 'دينار')
                {
                    this.$store.commit("GET_CREDITS_DI");
                }
                if(this.money_type == 'دولار')
                {
                    this.$store.commit("GET_CREDITS_DO");
                }

            },
            search_credits()
            {
                this.sum_money = 0 ;
                this.sum_moneyin = 0 ;
                var filterd =  this.$store.state.credits.credits;
                filterd = filterd.map(x=>{
                    if(x.Sand_id == null)
                    {
                        x.Sand_id = "";
                    }
                    if(x.tree_name == null)
                    {
                        x.tree_name = "";
                    }
                    if(x.brig_userAflet == null)
                    {
                        x.brig_userAflet = "";
                    }
                    if(x.brig_name == null)
                    {
                        x.brig_name = "";
                    }
                    if(x.currency == null)
                    {
                        x.currency = "";
                    }
                    if(x.Sand_money == null)
                    {
                        x.Sand_money = "";
                    }
                    if(x.Sand_moneyin == null)
                    {
                        x.Sand_moneyin = "";
                    }
                    if(x.cost_price == null)
                    {
                        x.cost_price = "";
                    }
                    if(x.gain == null)
                    {
                        x.gain = "";
                    }
                    if(x.Sand_date == null)
                    {
                        x.Sand_date = "";
                    }
                    if(x.sand_desc == null)
                    {
                        x.sand_desc = "";
                    }
                    if(x.Sand_notes == null)
                    {
                        x.Sand_notes = "";
                    }
                    if(x.Sand_operation == null)
                    {
                        x.Sand_operation = "";
                    }
                    if(x.sand_user == null)
                    {
                        x.sand_user = "";
                    }

                    return x;
                });

                filterd = filterd.filter(item=>item.Sand_id.match(this.search.sand_id));
                filterd = filterd.filter(item=>item.tree_name.match(this.search.tree_name));
                filterd = filterd.filter(item=>item.brig_userAflet.match(this.search.brig_userAflet));
                filterd = filterd.filter(item=>item.brig_name.match(this.search.brig_name));
                filterd = filterd.filter(item=>item.currency.match(this.search.currency));
                filterd = filterd.filter(item=>item.Sand_money.match(this.search.Sand_money));
                filterd = filterd.filter(item=>item.Sand_moneyin.match(this.search.Sand_moneyin));
                filterd = filterd.filter(item=>item.cost_price.match(this.search.cost_price));
                filterd = filterd.filter(item=>item.gain.match(this.search.gain));
                filterd = filterd.filter(item=>item.Sand_date.match(this.search.sand_date));
                filterd = filterd.filter(item=>item.sand_desc.match(this.search.sand_desc));
                filterd = filterd.filter(item=>item.Sand_notes.match(this.search.sand_notes));
                filterd = filterd.filter(item=>item.Sand_operation.match(this.search.Sand_operation));
                filterd = filterd.filter(item=>item.sand_user.match(this.search.sand_user));

                    if (this.search.date1 != "" && this.search.date1 != null) {
                        filterd = filterd.filter(item => item.Sand_date >= this.search.date1);
                        console.log(this.search.date1)
                    }
                    if (this.search.date2 != "" && this.search.date2 != null) {
                       this.search.date2 = moment().add(1,'day').format('YYYY-MM-DD');

                        filterd = filterd.filter(item => item.Sand_date <= this.search.date2);
                        console.log('date2 is : '+this.search.date2)
                    }

                filterd.map(x=>{
                    this.sum_money += parseInt(x.Sand_money);
                    this.sum_moneyin += parseInt(x.Sand_moneyin);

                })
                this.credits = filterd;

            },
            clear_date1(){

                this.search.date1 ='';

                this.search_credits();

            },
            clear_date2(){

                this.search.date2 ='' ;

                this.search_credits();

            },

        },
        watch:{
            watch_credits:function (new_credits) {
                this.pageOfItems = new_credits;
                this.credits = new_credits;
                var res = new_credits.map(x=>{
                    this.sum_money += parseInt(x.Sand_money);
                    this.sum_moneyin += parseInt(x.Sand_moneyin);

                })
            }
        },
        computed:{
            watch_credits:function () {
                return this.$store.state.credits.credits;
            }
        },
        created(){
            this.$store.commit("GET_CREDITS_DI");
        }
    }
</script>

<style scoped>

</style>